*, *:before, *:after {
  box-sizing: inherit;
}

.App {
    text-align: center;
    font-family: 'Mulish', sans-serif;

  }

  a:link {
    color:black;
    font-weight: bold;
    text-decoration: none;
  }
  
  a:hover {
    color:black;
    text-decoration: none;
  }
  
  a:active {
    color:black;
    text-decoration: none;
  }
  
  a:visited {
    color:black;
    font-weight: bold;
    text-decoration: none;
  }

  .signupcards {
    display: flex;
    justify-content: space-around;
    flex-direction: row;
    align-content: space-between;
    width: 100%;
    /* padding: 1%; */
    margin: 3% auto;
  }
  
  .signup {
    border: 1px solid gray;
    border-radius: 10%;
    margin: 10px auto;
    padding: 7.5% 10%;
    /* width: 100%; */
  }

  .signupspcdiv {
    width:50%;
    max-width: 472px;
    margin: 0 auto;
  }
  
  .signup img {
    max-width: 150px;
    height: auto;
  }
  
  .signup h3 {
    text-decoration: none;
  }
  
  .loginbutton {
    background-color: #505470;
    color: white;
    width: 70px;
    margin: 10px auto;
    padding: 5px;
    border-radius: 5px;
    text-decoration: none;
    border: none;
    cursor: pointer;
    margin: 10px;
  }
  
  .fooditem {
    background-color: #505470;
    color: white;
    border-radius: 5px;
    border: 1px solid black;
    width: 20%;
    margin: 10px;
  }
  
  .fooditemwrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  
  .modal-container {
    position: fixed;
    background-color: #505470;
    border: 1px solid black;
    left: 50%;
    transform: translate(-50%, -200%);
    -webkit-transform: translate(-50%, -200%);
    -ms-transform: translate(-50%, -200%);
    padding: 30px;
    border-radius: 10px;
    width:70%;
    max-width: 400px;
    -webkit-transition: -webkit-transform 200ms ease-out;
    transition: transform 200ms ease-out;
  }
  
  .modal:before {
    content: "";
    position: fixed;
    display: none;
    background-color: rgba(0,0,0,.8);
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }
  
  .modal:target:before {
    display: block;
  }
  
  .modal:target .modal-container {
    top: 20%;
    transform: translate(-50%, 0);
    -webkit-transform: translate(-50%, 0%);
    -ms-transform: translate(-50%, 0);
  }
  
  .abutton {
    background-color: #505470;
    text-decoration: none;
    color: white;
    padding: 5px;
    border-radius: 5px;
    margin: 10px;
    border: none;
    cursor: pointer;
  }
  
  .abutton:hover {
    background-color: #7b81ad
  }
  
  .abutton2 {
    background-color: white;
    text-decoration: none;
    color: #505470;
    padding: 5px;
    border-radius: 5px;
    margin: 10px;
    border: none;
    cursor: pointer;
  }
  
  .newpickupheader {
    color: white;
  }

  #createNewEntry {
    color: white;
  }

  .addEntryText {
    color:black;
  }

  #g-recaptcha {
    justify-content: center;
  }

  #registrationForm {
    text-align: center;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }

  .linkList {
    background-color: white;
  }

  .linkListDisplayHeader{
    position:-webkit-sticky;
    position:sticky;
    top:0;
    background-color: whitesmoke;
  }

  @media only screen and (max-width: 415px) {
    
    .linkList {
      width: 100%;
      margin: 0 auto;

    }

    .signupcards {
      width: 100%;
    }
    
    .signup {
      width: 90%;
      margin: 0 auto;
      padding: 3%;
      overflow: auto;
    }
  }